<template>
  <div>
    <h2 class="blue-grey--text nunito lighten-3">
      <b>Sync account with slurp</b>
    </h2>
    <v-divider class="my-2"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card-text>
            <v-text-field label="Email" outlined dense></v-text-field>
            <v-text-field label="Password" outlined dense></v-text-field>
            <div class="flex justify-end">
              <v-btn color="success">sync</v-btn>
            </div>
          </v-card-text>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
    <v-divider class="my-2"></v-divider>
    <v-card color="white" class="pa-3">
      <h2 class="blue-grey--text nunito lighten-3">
        <b>Outlets</b>
      </h2>
      <v-divider class="my-2"></v-divider>
      <v-container grid-list-xs>
        <v-row v-if="merchant_detail.outlets.length > 0">
          <v-col cols="12" v-for="(v, i) in merchant_detail.outlets" :key="i">
            <h4 class="source">{{ v.name }}</h4>
            <v-divider class="my-2"></v-divider>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Slurp",

  data() {
    return {
      data: {
        email: null,
        password: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  // beforeMount() {
  //   this.$store.dispatch("fetchAccount");
  // },
};
</script>
